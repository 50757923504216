const FORM = {
    signUp: (e) => {
        let parent = e.closest(".tab");
        let selected = parent.querySelectorAll(".activ");
        selected.forEach(elm => elm.classList.remove("activ"));
        e.classList.add("activ")
        let frms = document.querySelectorAll("[frm]");
        frms.forEach(frm => (frm.getAttribute("frm") == "signup") ? frm.show() : frm.hide());
    },
    signIn: (e) => {
        let parent = e.closest(".tab");
        let selected = parent.querySelectorAll(".activ");
        selected.forEach(elm => elm.classList.remove("activ"));
        e.classList.add("activ")
        let frms = document.querySelectorAll("[frm]");
        frms.forEach(frm => (frm.getAttribute("frm") == "signin") ? frm.show() : frm.hide());
    },
    forgotPassword: () => { },
    resetPassword: () => { },
    changePassword: () => { },
    setPassword: () => { },
};
const validate = async (e) => {
    let frm = e.closest("form");
    let popupDOM = e.closest(".popup-wrapper") || frm;
    e.setAttribute("disabled", true);
    let inputs = frm.querySelectorAll("[name]");
    let data = {};
    let error = [];
    inputs.forEach(input => {
        if (!input.validity) return;
        let name = input.getAttribute("name");
        let value = input.value;
        if (!input.validity.valid) {
            error.push({
                fld: input,
                msg: input.validationMessage
            })
            return;
        }
        if (["checkbox", "radio"].indexOf(input.type) == -1)
            data[name] = value;
        else
            data[name] = input.checked;
    })
    if (error.length > 0) {
        error[0].fld.focus();
        error.forEach(err => {
            if (!err.fld.validity.valueMissing) {
                err.fld.setAttribute("showerror", true)
            }
        })
        e.removeAttribute("disabled");
    } else {
        let actionpage = frm.getAttribute("action");
        let method = frm.getAttribute("method");
        let apicall = new API();

        let resp = await apicall[method](actionpage, data);
        switch (resp.status) {
            case "success":
                if (!!resp.message) {
                    popupDOM.innerHTML = `
                <div class="close" onclick="popup.remove(this)">&times;</div>
                <div class="content">
                    ${resp.message}
                </div>
                `;
                    let parent = popupDOM.parentElement;
                    let overlay = parent.querySelector(".overlay");
                    overlay.setAttribute("onclick", "popup.remove(this)");
                }
                if (!!resp.user) {
                    let info = {
                        user: resp.user.email,
                        pass: resp.user.password,
                        name: resp.user.name,
                    };
                    localStorage.setItem("auth", btoa(JSON.stringify(info)));
                }
                if (!!resp.redirect) {
                    setTimeout(() => {
                        location.href = resp.redirect;
                    },100)
                }
                if (!!resp.action) {
                    window[resp.action](resp, frm);
                }
                e.removeAttribute("disabled");
                break;
            default:
                let btn = frm.querySelector("frfs-password") || frm.querySelector("frfs-email");
                let p = btn.parentElement;
                let err = p.querySelector(".error");
                if (!!err) err.remove();
                p.classList.add("error");
                err = document.createElement("span");
                err.classList.add("error");
                err.innerHTML = resp.message;
                p.append(err);
                e.removeAttribute("disabled");
                
                setTimeout(() => {
                    err.remove();
                    p.classList.remove("error");
                }, 3000)
                break;
        }
    }
}

securitycheck = function (resp, frm) {
    let ans = document.createElement("div");
    ans.classList.add("col-md-12")
    ans.innerHTML = `
        <frfs-password required="true" message=" " label="${resp.data.question}" name="ans" complex="1"></frfs-password>
    `;
    let curRow = frm.querySelector(".row");
    let inputs = curRow.querySelectorAll(".col-md-12");
    curRow.insertBefore(ans, inputs[inputs.length - 1]);
    frm.method = "patch";
}