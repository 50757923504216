(async()=>{
    if(!location.pathname=="/"){
        return;
    }
    let time=new Date();
    let offset={
        "hours": Math.floor(-1 * time.getTimezoneOffset()/60),
        "minutes": (-1 * time.getTimezoneOffset())%60
    }
    document.cookie="offset="+JSON.stringify(offset);
    document.querySelectorAll("form").forEach(frm=>{
        let hh=document.createElement("input");
        hh.setAttribute("type","hidden");
        hh.setAttribute("name","offset");
        hh.setAttribute("value",JSON.stringify(offset));
        frm.appendChild(hh);
    })
})()