$(".banner-slider.owl-carousel, .testimonial-slider .owl-carousel").owlCarousel(
	{
		loop: true,
		autoplay: true,
		items: 1,
		nav: false,
		dots: false,
	},
);

$(".survey-slider .owl-carousel").owlCarousel({
	loop: true,
	autoplay: true,
	items: 3,
	nav: false,
	dots: false,
	margin: 30,
});

$(".accordion-area.cookie .item .que").click(function () {
	$(this).parent().children(".ans").slideToggle();
	$(this).parent().toggleClass("open");
});

$(".toggle").click(function () {
	$(this).parent().toggleClass("menu-open");
	$(this).parent().children("ul").slideToggle();
});


$(document).ready(function(){
    $('.counter-value').each(function(){
        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        },{
            duration: 20000,
            easing: 'swing',
            step: function (now){
                $(this).text(Math.ceil(now));
            }
        });
    });
});

// Disable right click on web page
$("html").on("contextmenu",function(e){
    return false;
});

// Disable cut, copy and paste on web page
$('html').bind('cut copy paste', function (e) {
     e.preventDefault();
});

document.addEventListener('keydown', function(event) {
  // Check if Control key (or Command key on Mac) and U key are pressed
  if ((event.ctrlKey || event.metaKey) && event.key === 'u') {
    // Prevent the default behavior
    event.preventDefault();
  }
});
