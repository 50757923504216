const popup = {
	load: async function (frm, data = {},target=null) {
		document.querySelectorAll(".popup").forEach((e) => {e.classList.remove("open")});
		let popupElm = document.querySelector(`[data-popup="${frm}"]`);
		if (!popupElm) {
			let temp = await fetch("/layouts/" + frm + ".html");
			let popupDOM = document.createElement("div");
			let html = await temp.text();
			html = Object.keys(data).reduce((a, c) => {
				return a.replace(new RegExp(`{{${c}}}`, "g"), data[c]);
			}, html);
			popupDOM.innerHTML = html;
			popupDOM.classList.add("popup");
			popupDOM.setAttribute("data-popup", frm);
			document.body.appendChild(popupDOM);
			popupElm = popupDOM;
		}
		popupElm.classList.add("open");
		if(!!target){
			popupElm.querySelector(target).click();
		}
		Object.keys(data).forEach(key=>{
			let inp=popupElm.querySelector(`[name=${key}]`);
			if(!!inp)inp.value=data[key];
		})
		popupElm.style="";
	},
	reload:async function(frm,data={},target=null){
		let popupElm = document.querySelector(`[data-popup="${frm}"]`);
		if (!popupElm) {
			let temp = await fetch("/layouts/" + frm + ".html");
			let popupDOM = document.createElement("div");
			let html = await temp.text();
			html = Object.keys(data).reduce((a, c) => {
				return a.replace(new RegExp(`{{${c}}}`, "g"), data[c]);
			}, html);
			popupDOM.innerHTML = html;
			popupDOM.classList.add("popup");
			popupDOM.setAttribute("data-popup", frm);
			document.body.appendChild(popupDOM);
		}
	},
	close: function () {
		let temp = document.querySelector(".popup.open");
		temp.classList.remove("open")
	},
	remove:function(e){
		let target=e.closest(".popup");
		let frm=target.getAttribute("data-popup");
		target.remove();
		popup.reload(frm);
	}
};
window.addEventListener("load", () => {
	if (location.pathname.startsWith("/user/verify")) {
		let id = location.pathname.split("/").pop();
		popup.load("homepage-setpassword", {
			user: id,
		});
	}
	if (location.pathname.startsWith("/user/forgot")) {
		popup.load("homepage-forgot");
	}
	if (location.pathname.startsWith("/user/resetpass")) {
		let key = location.pathname.split("/").pop();
		console.log(key);
		popup.load("homepage-reset", {
			key,
		});
	}
});
