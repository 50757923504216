;(async()=>{
    let url=new URL(location.href);
    let ref=url.searchParams.get("ref");
    if(!!ref){
        setTimeout(()=>{
            let vars={
                ref
            }
            popup.load('homepage-form',vars,`[signup]`);
        },1000)
    }
})();